:root {
  /**
   * @tokens Basic colors
   * @presenter Color
   */
  --color-blue-dark: #27335c;
  --color-blue: #324380;
  --color-blue-light: #ddf4ff;
  --color-gray-dark: #444;
  --color-gray: #dfe4ec;
  --color-gray-medium: #9f9f9f;
  --color-gray-light: #f3f4f8;
  --color-white: #fff;
  --color-green: #43b581;
  --color-green-light: #c4e9d6;
  --color-red: #f04848;
  --color-red-light: #f76c6c;
  --color-yellow: #ffeec0;
  --color-orange: #f9d249;

  /**
   * @tokens Concept colors
   * @presenter Color
   */
  --color-background-default: var(--color-white);
  --color-background-disabled: var(--color-gray-light);
  --color-secondary-box-background: var(--color-gray-light);
  --color-primary-box-background: var(--color-blue-light);
  --color-header-background: var(--color-blue-dark);
  --color-search-bar-background: var(--color-gray);
  --color-box-border: var(--color-gray);
  --color-text-default: var(--color-gray-dark);
  --color-text-bright: var(--color-white);
  --color-text-secondary: var(--color-gray-medium);
  --color-text-highlight: var(--color-yellow);
  --color-text-headline: var(--color-blue);
  --color-primary-element: var(--color-blue);
  --color-primary-element-hover: var(--color-blue-dark);
  --color-input-border: var(--color-blue);
  --color-input-border-disabled: var(--color-gray);
  --color-input-placeholder: var(--color-gray-medium);
  --color-input-background: var(--color-white);
  --color-input-background-focus: var(--color-gray-light);
  --color-input-outline-focus: var(--color-blue);
  --color-background-dark-hover: var(--color-gray);
  --color-background-bright-hover: var(--color-blue-light);
  --color-active-tab: var(--color-blue);
  --color-attention: var(--color-red-light);
  --color-success: var(--color-green);
  --color-error: var(--color-red);
  --color-warning: var(--color-orange);

  /**
   * @tokens BorderRadius
   * @presenter BorderRadius
   */
  --border-radius-input: 4px;

  /**
   * @tokens Borders
   * @presenter Border
   */
  --border-input: 1px solid var(--color-input-border);
  --border-box: 1px solid var(--color-box-border);
  --border-box-radius: 4px;

  /**
   * @tokens Font families
   * @presenter FontFamily
   */
  --font-family-default: Arial, sans-serif;

  /**
   * @tokens Font sizes
   * @presenter FontSize
   */
  --font-size-default: 12px;
  --font-size-small: 11px;
  --font-size-large: 14px;
  --font-size-h2: 18px;
  --font-size-h1: 24px;
  --font-size-extra-large: 30px;

  /**
   * @tokens Line heights
   * @presenter LineHeight
   */
  --line-height-text: 1.5;
  --line-height-heading: 1.3;

  /**
  * @tokens Others
  */
  --app-header-height: 65px;
  --footer-height: 65px;
  --outline-input-focus: 2px solid var(--color-input-outline-focus);
  --grid-column-width: 45px;
  --grid-column-count: 26;
  --navigation-width: calc(6 * var(--grid-column-width));
  --navigation-padding-top: 25px;
  --app-width: calc(var(--grid-column-count) * var(--grid-column-width));
  --content-width: calc(20 * var(--grid-column-width));
  --screen-padding-left: 20px;
  --input-height-default: 25px;
  --input-height-big: 30px;

  /**
    If a component needs to assign a z-index, always define the z-index here
    and assign a number which considers the other z-index hierarchies
   */
  --z-index-toolbar: 1;
  --z-index-search-bar-overlay: 2;
  --z-index-search-bar-overlay-aside: 3;
  --z-index-autocomplete-menu: 4;
  --z-index-autocomplete: 5;
  --z-index-app-header: 6;
  --z-index-support-info: 7;
}